import { Component } from "react"
import { css } from '@emotion/react'
import { Container ,Grid, Typography} from '@material-ui/core';
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'

// FINANCIERA COMPONENTS
import {  CardElevation } from '@findep/mf-landings-core'
import SimpleAccordion from '../../elements/ControlledAccordions'
//ICONS MATERIAL UI
import appAfiImg from '../../../assets/images/footers/afi-appfin-app.png'
import appAfiRecImg from '../../../assets/images/footers/recomienda-pasos.gif'

 const imgCss = css`
 width: 30%;
 float: right;
 `
class ClientesCmp extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            option:'',
            op_pago:false,
            op_eduf:false
        }
    }
    
  async  componentDidMount() {
        let urlParams = new URLSearchParams(window.location.search)
        const option = await urlParams.get('option') 
        this.setState({option: option})      
    }

    componentDidUpdate(_, prevState) {
        const {option } = this.state
        if (this.state.option !== prevState.option){
            
          if(option ==='edfin'){            
                this.setState({op_eduf: true})
            }else if(option ==='pagos'){
                this.setState({op_pago: true})
            }

        }
    }

    render() {
        
          const {op_eduf,op_pago } = this.state        
        const {            
            titleIcon='Clientes',
            txtTitlePago=<span style={{color:"rgb(50 68 14)"}}><strong>Opciones de pago</strong></span>,
            txtCntPago=<span>
                            <span style={{color:"rgb(213 28 28)"}}><strong>Apoyo Financiero te ofrece las siguientes opciones de pago a fin de que elijas la que más te convenga.</strong></span><br/><br/>
                            <span style={{color:"rgb(50 68 14)"}}>Descarga nuestra App para realizar tus pagos con tarjeta de débito/cuenta de banco.</span><br/><br/>                                                      
                            <strong>PayNearMe (en tiendas 7-Eleven, Farmacias CVS, Walmart y Family Dollar participantes)</strong><br/><br/>
                            Para tu comodidad, ahora puedes pagar tu préstamo con efectivo a cualquier hora del día.
                            <ul>
                                <li>Presenta tu código de barras personalizado.</li>
                                <li>Muestra al cajero tu código.</li>
                                <li>Conserva tu recibo de pago como comprobante.</li>
                            </ul>
                            Si requieres copia del código de barras de PayNearMe llama a tu sucursal para que te envíen el código por correo electrónico o vía mensaje de texto a tu celular.<br/>
                            Para encontrar un establecimiento PayNearMe cerca de ti, visita: <a href="https://home.paynearme.com/locations/" target="_blank" rel="nofollow noopener noreferrer" style={{color:"rgb(50 68 14)"}}>paynearme.com/es/payment-locations</a><br/>
                        </span>,
            txtTitleApp=<span style={{color:"rgb(50 68 14)"}}><strong>Descarga nuestra App</strong></span>,
            txtCntApp=<span>
                        <span style={{color:"rgb(213 28 28)"}}><strong>¡Apoyo Financiero ahora en tu celular!</strong></span><br/><br/>
                        <span style={{color:"rgb(213 28 28)"}}><strong>Te invitamos a descargar la aplicación móvil de Apoyo Financiero en donde podrás:</strong></span><br/><br/>                                            
                        Realizar tus pagos de forma segura y ágil<br/><br/>
                        Programar pagos recurrentes usando tu cuenta bancaría o tarjeta de débito<br/><br/>                        
                        Revisar tu saldo (Histórico de pagos)<br/><br/>
                        Consultar historial de movimientos<br/><br/>
                        <strong>Pagos de $0 Comisión: Los clientes que realicen sus pagos a través de la app de Apoyo Financiero o con el código de barras NO tendrán cargos adicionales por el pago.</strong><br/><br/>
                        <strong>¡Maneja tu cuenta desde cualquier lugar y cuando quieras!</strong>
                        </span>,
            txtTitleRecomienda=<span style={{color:"rgb(50 68 14)"}}><strong>Programa de Recomienda a tus familiares y amigos</strong></span>,
            txtCntRecomienda=<span>
                                <span style={{color:"rgb(213 28 28)"}}><strong>Recomienda a tus familiares y amigos</strong></span><br/><br/>
                                El programa de “Recomienda a tus familiares y amigos” es un programa mediante el cual los clientes activos de 
                                Apoyo Financiero pueden recomendar a sus familiares y amigos para solicitar un préstamo personal con nuestra 
                                compañia y a cambio, recibir una compensación de $50 por cada persona recomendada a la que se le entregue su primer préstamo.<br/><br/>
                                <span style={{color:"rgb(213 28 28)"}}><strong>Cómo agregar a sus recomendados en nuestra aplicación movil:</strong></span><br/><br/>
                                <span style={{color:"rgb(213 28 28)"}}><strong>Si aun no cuenta con nuestra aplicación móvil, ¡descárguela hoy mismo!</strong></span><br/><br/>
                                ¡No espere más, empiece a recomendar y a ganar!
                            </span>,
            txtTitlePromotions=<span style={{color:"rgb(50 68 14)"}}><strong>Promociones</strong></span>,
            txtCntPromotions='',

            txtTitleEduf=<span style={{color:"rgb(50 68 14)"}}><strong>Educación Financiera</strong></span>,
            txtCntEduf=<span>
                            <span style={{color:"rgb(213 28 28)"}}><strong>Apoyo Financiero reportará al buró de crédito cómo van tus pagos, así crearás tu propio historial crediticio, por eso te sugerimos que tomes en cuenta los siguientes puntos:</strong></span><br/><br/>
                            El buró de crédito empezará a calificar tu comportamiento a través de puntos (score). Mientras más alto sea tu score, mejor.<br/><br/>
                            Deberás cumplir puntualmente con tus pagos,<br/><br/>
                            Recuerda que no debes tomar deudas a nombre de otras personas, pues si no cumplen con los pagos afectarán tu perfil de crédito.<br/><br/>
                            No tengas deudas que no puedas pagar, pues esto disminuirá tu puntaje o score.<br/><br/>
                            <hr/>
                            <span style={{color:"rgb(213 28 28)"}}><strong>5 variables muy importantes que debes vigilar:</strong></span><br/><br/>
                            <strong>a. Historial de Pagos:</strong> Te informa de los pagos que has hecho, los atrasos (si los has tenido) y los pagos que te faltan por hacer.<br/><br/>
                            <strong>b. Cantidad Adeudada:</strong> Es el dinero que debes por tipo de cuenta. Te informa también el % del crédito que ya usaste y el % de la deuda que todavía<br/><br/>tienes.<br/><br/>
                            <strong>c. Antigüedad del Historial de Crédito:</strong> Es el tiempo que ha pasado desde que abriste las cuentas.<br/><br/>
                            <strong>d. Nuevo Crédito:</strong> Es la información actualizada de las cuentas que has abierto y de las solicitudes de crédito que has hecho recientemente.<br/><br/>
                            <strong>e. Tipos de crédito en uso:</strong> Son los diferentes tipos de cuenta que tienes (tarjeta de crédito, hipotecario, auto, personal).<br/><br/>
                            <hr/>
                            El Departamento de Protección e innovación Financiera de California (DFPI por sus siglas en inglés), requiere que Apoyo Financiero ofrezca información<br/><br/>
                            relativa al Programa de Educación Financiera, el cual es ofrecido en línea por el National Financial Educators Council (NFEC). El Programa de Educación<br/><br/>
                            Financiera ofrecido por Apoyo Financiero y el National Financial Educators Council (NFEC), está disponible de forma gratuita para todos nuestros clientes.<br/><br/>
                            Solo tiene que registrarse en la siguiente página web:<a href="http://www.ocl.apoyo-financiero.com/" target="_blank" rel="nofollow noopener noreferrer" style={{color:"rgb(50 68 14)"}}> www.ocl.apoyo-financiero.com</a><br/><br/>

                        </span>

            } = this.props
        return (
            <Container justify="center">
                <br/><br/>
                <Grid container justify="left">
                    <Grid item style={{ textAlign: "left" }}>                        
                        <Typography component='h3' variant="h4" color="primary">
                        <strong style={{color:"#698d21"}}>{titleIcon}</strong>                        
                        </Typography>
                    </Grid>
                </Grid>
                <br/><br/>
               <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <CardElevation>                    
                    <br/><br/>

                    {op_pago &&
                        <SimpleAccordion header expanded={op_pago} titulo={txtTitlePago}>
                            <Grid Container>
                            <Grid item xs={12}>
                                {txtCntPago}                                     
                            </Grid>
                            
                            </Grid>
                        </SimpleAccordion>
                    }

                    {!op_pago &&
                        <SimpleAccordion header expanded={op_pago} titulo={txtTitlePago}>
                            <Grid Container>
                            <Grid item xs={12}>
                                {txtCntPago}                                     
                            </Grid>
                            
                            </Grid>
                        </SimpleAccordion>
                    }

                    <SimpleAccordion header expanded={false} titulo={txtTitleApp}>
                        <Grid Container>
                            <Grid item xs={12}>
                                <img src={appAfiImg} alt="img-afi-appfin-app" css={imgCss}/>
                            </Grid>
                        <Grid item xs={12}>
                            {txtCntApp}                                     
                        </Grid>
                        
                        </Grid>
                    </SimpleAccordion>


                {this.props.pageContext?.locale === "esES" &&
                    <SimpleAccordion header expanded={false} titulo={txtTitleRecomienda}>
                        <Grid Container>
                            <Grid item xs={12}>
                                <img src={appAfiRecImg} alt="img-recomienda-pasos" css={imgCss}/>
                            </Grid>
                        <Grid item xs={12}>
                            {txtCntRecomienda}                                     
                        </Grid>
                        
                        </Grid>
                    </SimpleAccordion>
                }


                    <SimpleAccordion header expanded={false} titulo={txtTitlePromotions}>
                        <Grid Container>
                        <Grid item xs={12}>
                            {txtCntPromotions}                                     
                        </Grid>
                        
                        </Grid>
                    </SimpleAccordion>
{op_eduf &&
                     <SimpleAccordion header expanded={op_eduf} titulo={txtTitleEduf}>
                        <Grid Container>
                        <Grid item xs={12}>
                            {txtCntEduf}                                     
                        </Grid>
                        
                        </Grid>
                    </SimpleAccordion>
    }     
    {!op_eduf &&
                     <SimpleAccordion header expanded={op_eduf} titulo={txtTitleEduf}>
                        <Grid Container>
                        <Grid item xs={12}>
                            {txtCntEduf}                                     
                        </Grid>
                        
                        </Grid>
                    </SimpleAccordion>
    }        
                        
                    </CardElevation>
                </Grid>
                
                </Grid>
            </Container>

        )
    }
}

ClientesCmp.propTypes = {      
    titlePrimary: PropTypes.string,
    titleIcon: PropTypes.string,
    txtTitlePago: PropTypes.string,
    txtCntPago: PropTypes.string,
    txtTitleApp: PropTypes.string,
    txtCntApp: PropTypes.string,
    txtTitleRecomienda: PropTypes.string,
    txtCntRecomienda: PropTypes.string,
    txtTitlePromotions: PropTypes.string,
    txtCntPromotions: PropTypes.string,
    txtTitleEduf: PropTypes.string,
    txtCntEduf: PropTypes.string
}

export default withThemeProps(ClientesCmp, 'VDPNClientes')






